import { isEmpty } from "lodash-es";
import {
  Approximate,
  Duration,
  LocalDate,
  LocalTime,
  OffsetTime,
  QuestionnaireAnswer,
  QuestionnaireAnswerSet,
  QuestionnaireAnswerValue,
  QuestionnaireCalculation,
  QuestionnaireElement,
  QuestionnaireElementType,
  SerialConjunction
} from "@remhealth/apollo";
import {
  PlaceholderContext,
  mergeParagraphs,
  removeParagraphs,
  replaceAnswerPlaceholders,
  replacePlaceholders
} from "@remhealth/core";
import { DateFormats } from "@remhealth/ui";
import { startsWithBlockTag } from "@remhealth/compose";
import { QuestionFlavor, hasLinkedSectionForm, resolveQuestionFlavor } from "../flavors";
import { type QuestionnaireAnswerContext, QuestionnaireContext, QuestionnaireRenderContext } from "../contexts";
import { answerValueHasValue, getAnswerOptionDisplay, getAnswerValueDisplay, isAnswerOptionValue } from "../utils";
import { getQuestionDependencies } from "../dependencies";

export interface RenderQuestionnaireOptions extends RenderQuestionOptions {
  questionnaireContext: QuestionnaireContext;
  placeholderContext: PlaceholderContext | undefined;
  isPatientView?: boolean;
  depth?: number;
}

export function renderQuestionnaire(elements: QuestionnaireElement[], answers: QuestionnaireAnswer[], options: RenderQuestionnaireOptions) {
  const { questionnaireContext, placeholderContext, isPatientView = false, depth = 0 } = options;

  const visibleQuestions = getQuestionDependencies(elements, questionnaireContext)
    .getVisible(answers)
    .filter(q => filterQuestionnaireElement(q, isPatientView));

  let content = "";
  let currentLine = "";

  const context: QuestionnaireRenderContext = {
    getElementAt: (index) => elements[index],
    getElementIndex: (linkId) => elements.findIndex(e => e.linkId === linkId),
    getValuesByLinkId: (linkId) => answers.flatMap(a => a.linkId === linkId ? a.values : []),
  };

  visibleQuestions.forEach(e => {
    if (!e.partOfNoteNarrative) {
      return;
    }

    if (hasLinkedSectionForm(e) && depth === 0) {
      const targetAnswer = answers.find(a => a.linkId === e.linkId);

      if (targetAnswer) {
        const valueGridAnswers = targetAnswer.values.flatMap(v => v.valueGrid?.flatMap(v => v.answers ?? []) ?? []);
        const nestedFormOutput = renderQuestionnaire(e.form.resource?.elements ?? [], valueGridAnswers, { ...options, depth: depth + 1 });

        content += currentLine + nestedFormOutput;
        currentLine = "";
      }
      return;
    }

    const output = renderQuestion(e, context, options);

    if (output) {
      switch (output.contentType) {
        case "break": {
          if (currentLine) {
            content += `<p>${currentLine}</p>`;
          }
          currentLine = "";
          break;
        }
        case "paragraph-body": {
          if (currentLine) {
            currentLine += output.content ? " " + output.content : "";
          } else {
            currentLine = output.content;
          }

          if (currentLine && e.trailingParagraphBreak) {
            content += `<p>${currentLine}</p>`;
            currentLine = "";
          }
          break;
        }
        case "html": {
          if (currentLine) {
            content += `<p>${currentLine}</p>`;
            currentLine = "";
          }

          content += output.content;
        }
      }
    }
  });

  if (currentLine) {
    content += `<p>${currentLine}</p>`;
  }

  if (placeholderContext) {
    return replacePlaceholders(content, placeholderContext);
  }

  return content;
}

function filterQuestionnaireElement(question: QuestionnaireElement, isPatientView: boolean) {
  if (isPatientView) {
    return question.includeInPatientView;
  }
  return true;
}

export interface RenderQuestionOptions {
  militaryTime: boolean;
  /**
   * When true, will favor AnswerOption's Output, even if it is blank.
   * When false, will allow falling back to AnswerOption's display if Output is blank.
   * @default true
   */
  preferOptionOutput?: boolean;
  renderInline?: boolean;
}

export interface RenderedQuestion {
  contentType: "paragraph-body" | "html" | "break";
  content: string;
}

export function renderQuestion(element: QuestionnaireElement, context: QuestionnaireRenderContext, options: RenderQuestionOptions): RenderedQuestion | null {
  const { militaryTime, preferOptionOutput = true, renderInline = false } = options;
  const flavor = resolveQuestionFlavor(element);

  switch (flavor) {
    case QuestionFlavor.Instructions: {
      const renderedOutput = element.text?.value ?? "";
      if (startsWithBlockTag(renderedOutput)) {
        return { contentType: "html", content: renderedOutput };
      }
      return { contentType: "html", content: `<p>${renderedOutput}</p>` };
    }
    case QuestionFlavor.Content: {
      const renderedOutput = element.text?.value ?? "";
      const [html, isMultiparagraph] = removeParagraphs(renderedOutput, "single-or-none", true);
      return { contentType: element.trailingParagraphBreak || isMultiparagraph ? "html" : "paragraph-body", content: html };
    }
    case QuestionFlavor.Header: {
      const showHeader = headerGroupQuestionsHasAnswers(element, context);
      if (showHeader) {
        const renderedOutput = `<h4>${element.text?.value ?? ""}</h4>`;
        return { contentType: "html", content: renderedOutput };
      }
      return null;
    }
    case QuestionFlavor.MultilineText:
    case QuestionFlavor.Ranking: {
      const renderedAnswers = renderAnswerOutput(element, context, preferOptionOutput, militaryTime);
      if (renderedAnswers) {
        if (renderInline) {
          const renderedOutput = mergeParagraphs(element.text?.value ?? "", renderedAnswers, ": ");
          return { contentType: "html", content: renderedOutput };
        }
        const renderedOutput = renderQuestionWithAnswer(element, renderedAnswers) ?? "";
        if (startsWithBlockTag(renderedOutput)) {
          return { contentType: "html", content: renderedOutput };
        }
        return { contentType: "html", content: `<p>${renderedOutput}</p>` };
      }
      return null;
    }
    case QuestionFlavor.ParagraphBreak: {
      return { contentType: "break", content: "" };
    }
    case QuestionFlavor.Checkbox: {
      const answer = getSingleAnswerValue(element, context);
      if (answer?.valueBoolean) {
        // Render "Yes" if there is a $answers placeholder
        const renderedOutput = renderQuestionSingleLine(element, "Yes") ?? "";
        return { contentType: "paragraph-body", content: renderedOutput };
      }
      return null;
    }
    case QuestionFlavor.Grid: {
      const renderedOutput = renderGridAnswerOutput(element, context, options);
      return renderedOutput ? { contentType: "html", content: renderedOutput } : null;
    }
    case QuestionFlavor.SingleDecision:
    case QuestionFlavor.MultiDecision:
      return null;
    case QuestionFlavor.Signature:
    case QuestionFlavor.YesNo:
    case QuestionFlavor.SingleChoice:
    case QuestionFlavor.MultiChoice:
    case QuestionFlavor.SinglelineText:
    case QuestionFlavor.Date:
    case QuestionFlavor.DateTime:
    case QuestionFlavor.Time:
    case QuestionFlavor.Duration:
    case QuestionFlavor.Integer:
    case QuestionFlavor.Decimal:
    case QuestionFlavor.StaffSearch:
    case QuestionFlavor.ServiceSearch:
    case QuestionFlavor.DocumentReference:
    case QuestionFlavor.UsStateLookup:
    case QuestionFlavor.EhrLookup:
    case QuestionFlavor.Calculated: {
      const renderedAnswers = renderAnswerOutput(element, context, preferOptionOutput, militaryTime);
      if (renderedAnswers) {
        if (renderInline) {
          const renderedOutput = mergeParagraphs(element.text?.value ?? "", renderedAnswers, ": ");
          return { contentType: "html", content: renderedOutput };
        }
        const renderedOutput = renderQuestionSingleLine(element, renderedAnswers) ?? "";
        return { contentType: "paragraph-body", content: renderedOutput };
      }
      return null;
    }
  }
}

export function renderAnswerOutput(element: QuestionnaireElement, context: QuestionnaireRenderContext, preferOptionOutput: boolean, militaryTime: boolean): string | null {
  const flavor = resolveQuestionFlavor(element);

  switch (flavor) {
    case QuestionFlavor.MultilineText: {
      return getMultilineAnswerOutput(element, context);
    }
    case QuestionFlavor.Instructions:
    case QuestionFlavor.ParagraphBreak:
    case QuestionFlavor.Content:
    case QuestionFlavor.Header: {
      return "";
    }
    case QuestionFlavor.Ranking: {
      return getRankingAnswerOutput(element, context) ?? "";
    }
    case QuestionFlavor.YesNo:
    case QuestionFlavor.SingleChoice: {
      return getSingleSelectAnswerOutput(element, context, preferOptionOutput, militaryTime);
    }
    case QuestionFlavor.MultiChoice: {
      return getMultiChoiceAnswerOutput(element, context, preferOptionOutput, militaryTime);
    }
    case QuestionFlavor.SinglelineText: {
      const answer = getSingleAnswerValue(element, context);
      return answer?.valueString ?? "";
    }
    case QuestionFlavor.Date: {
      const answer = getSingleAnswerValue(element, context);
      return DateFormats.date(LocalDate.toDate(answer?.valueDate));
    }
    case QuestionFlavor.DateTime: {
      const answer = getSingleAnswerValue(element, context);
      return DateFormats.dateTime(Approximate.toDate(answer?.valueApproximate), militaryTime);
    }
    case QuestionFlavor.Time: {
      const answer = getSingleAnswerValue(element, context);
      return DateFormats.time(LocalTime.toDate(answer?.valueTime), false);
    }
    case QuestionFlavor.Checkbox: {
      return null;
    }
    case QuestionFlavor.Calculated:
    case QuestionFlavor.Duration:
    case QuestionFlavor.Integer:
    case QuestionFlavor.Decimal: {
      const answer = getSingleAnswerValue(element, context);

      if (answer?.valueDecimal && element.presentationHint === "USCurrency") {
        return `$${answer.valueDecimal.toFixed(2).toString()}`;
      }

      return answer ? getAnswerValueDisplay(answer, militaryTime) ?? null : null;
    }
    case QuestionFlavor.StaffSearch:
    case QuestionFlavor.ServiceSearch:
    case QuestionFlavor.DocumentReference: {
      const answer = getSingleAnswerValue(element, context);
      return answer?.valueReference?.display ?? null;
    }
    case QuestionFlavor.UsStateLookup:
    case QuestionFlavor.EhrLookup: {
      const answer = getSingleAnswerValue(element, context);
      return answer?.valueCode?.display ?? null;
    }
    case QuestionFlavor.Grid:
    case QuestionFlavor.SingleDecision:
    case QuestionFlavor.MultiDecision: {
      return null;
    }
  }
  return null;
}

export function getComputeQuestionsOutput(calculateQuestion: QuestionnaireElement, context: QuestionnaireRenderContext): string | undefined {
  const value = computeCalculatedAnswer(calculateQuestion, context);
  return value ? getAnswerValueDisplay(value, false) : undefined;
}

export function computeCalculatedAnswer(calculateQuestion: QuestionnaireElement, context: QuestionnaireAnswerContext): QuestionnaireAnswerValue | undefined {
  const selectedAnswers = calculateQuestion.dependsOn.flatMap(d => context.getValuesByLinkId(d.elementLinkId));

  if (!calculateQuestion.calculation) {
    return undefined;
  }

  switch (calculateQuestion.calculation) {
    case QuestionnaireCalculation.Sum: {
      const numbers = selectedAnswers.flatMap(a => a.valueDecimal ?? a.valueInteger ?? []);

      if (numbers.length === 0) {
        return undefined;
      }

      const value = numbers.reduce((a, b) => a + b, 0);

      if (calculateQuestion.type === QuestionnaireElementType.Integer) {
        return { valueInteger: Math.round(value) };
      }

      return { valueDecimal: value };
    }

    case QuestionnaireCalculation.Multiply: {
      const numbers = selectedAnswers.flatMap(a => a.valueDecimal ?? a.valueInteger ?? []);

      if (numbers.length === 0) {
        return undefined;
      }

      const value = numbers.reduce((a, b) => a * b, calculateQuestion.operand ?? 1);

      if (calculateQuestion.type === QuestionnaireElementType.Integer) {
        return { valueInteger: Math.round(value) };
      }

      return { valueDecimal: value };
    }

    case QuestionnaireCalculation.Difference: {
      if (calculateQuestion.type !== QuestionnaireElementType.Duration) {
        return undefined;
      }

      const dateTimes = selectedAnswers.flatMap(a =>
        Approximate.toDateTime(a.valueApproximate)
        ?? OffsetTime.toDateTime(a.valueOffsetTime)
        ?? LocalTime.toDateTime(a.valueTime)
        ?? []);

      if (dateTimes.length >= 2 && dateTimes[0] && dateTimes[1]) {
        let diff = dateTimes[0].diff(dateTimes[1]);
        diff = diff.valueOf() < 0 ? diff.negate() : diff;
        return { valueDuration: Duration.fromLuxon(diff) };
      }

      return undefined;
    }
  }
}

export function renderGridAnswerOutput(element: QuestionnaireElement, context: QuestionnaireRenderContext, options: RenderQuestionOptions): string | undefined {
  if (!element.form?.resource) {
    return undefined;
  }

  const columns = element.form.resource.elements.filter(e => e.isPresentInSummary) ?? [];

  if (columns.length === 0) {
    return undefined;
  }

  const rows = context.getValuesByLinkId(element.linkId).flatMap(a => a.valueGrid ?? []);
  if (rows.length === 0) {
    return undefined;
  }

  let output = element.text?.plainText?.trim() ? `<h4>${element.text?.value ?? ""}</h4>` : "";

  output += '<table class="subform">';

  output += `<thead><tr>${columns.map(column => renderGridHeaderCell(column)).join("")}</tr></thead>`;
  output += "<tbody>";
  output += rows.map(row => `<tr>${columns.map(column => renderGridCell(column, row, options.militaryTime)).join("")}</tr>`).join("");
  output += "</tbody>";

  output += "</table>";

  return output;
}

function renderGridHeaderCell(column: QuestionnaireElement) {
  return `<th>${removeParagraphs(column.text?.value ?? "", "single-or-none")}</th>`;
}

function renderGridCell(column: QuestionnaireElement, row: QuestionnaireAnswerSet, militaryTime: boolean) {
  const answer = row.answers.find(a => a.linkId === column.linkId)?.values[0];
  const answerOutput = answer ? getAnswerValueDisplay(answer, militaryTime) : undefined;
  return `<td>${answerOutput ?? ""}</td>`;
}

function getSingleAnswerValue(element: QuestionnaireElement, context: QuestionnaireRenderContext): QuestionnaireAnswerValue | undefined {
  const elementValues = context.getValuesByLinkId(element.linkId);
  return elementValues.find(v => answerValueHasValue(v));
}

function getRankingAnswerOutput(element: QuestionnaireElement, context: QuestionnaireRenderContext): string {
  const elementValues = context.getValuesByLinkId(element.linkId);

  if (elementValues.length === 0) {
    return "";
  }

  const listItems = elementValues.map((answer) => {
    const selectedAnswerOption = element.answerOptions.find(ao => ao.valueString === answer.valueString);
    const answerOutput = isEmpty(selectedAnswerOption?.output)
      ? selectedAnswerOption?.valueString?.toLowerCase() ?? ""
      : selectedAnswerOption?.output ?? "";

    return `<li>${answerOutput}</li>`;
  });

  return `<ol>${listItems.join("")}</ol>`;
}

function getMultilineAnswerOutput(element: QuestionnaireElement, context: QuestionnaireRenderContext) {
  const elementValues = context.getValuesByLinkId(element.linkId);
  return elementValues.flatMap(f => f.valueText?.plainText?.trim() && f.valueText?.value ? f.valueText.value : []).join(" ") ?? "";
}

function renderQuestionWithAnswer(element: QuestionnaireElement, renderedAnswers: string): string | null {
  return replaceAnswerPlaceholders(element.output?.value ?? "", renderedAnswers);
}

function renderQuestionSingleLine(element: QuestionnaireElement, answerOptionOutput: string) {
  let html = renderQuestionWithAnswer(element, answerOptionOutput);

  if (html) {
    html = removeParagraphs(html, "first-only");
  }

  return html;
}

const commaSeperator = ", ";
const spaceSeparator = " ";

function getMultiChoiceAnswerOutput(element: QuestionnaireElement, context: QuestionnaireRenderContext, preferOptionOutput: boolean, militaryTime: boolean) {
  const answerValues = context.getValuesByLinkId(element.linkId);

  if (answerValues.length === 0 || answerValues.every(v => !answerValueHasValue(v))) {
    return null;
  }

  const originalOrder = element.answerOptions;
  let otherValueOutput: string | undefined;

  if (element.type === "OpenChoice") {
    const otherValue = answerValues.find(v => !originalOrder.some(o => isAnswerOptionValue(o, v)));
    if (otherValue) {
      otherValueOutput = getAnswerValueDisplay(otherValue, militaryTime);
    }
  }

  const orderedValues = originalOrder
    .flatMap<string>(o => {
      const answerValue = answerValues.find(v => isAnswerOptionValue(o, v));
      return !answerValue ? [] : getAnswerWithOutput(element, answerValue, preferOptionOutput, militaryTime);
    });

  if (otherValueOutput) {
    orderedValues.push(otherValueOutput);
  }

  if (orderedValues.length === 0) {
    return "";
  }

  if (!element.conjunction) {
    return orderedValues.join(commaSeperator);
  }

  if (element.conjunction === SerialConjunction.None) {
    return orderedValues.join(spaceSeparator);
  }

  if (orderedValues.length === 1) {
    return orderedValues[0];
  }

  return orderedValues.slice(0, -1).join(commaSeperator)
    + (orderedValues.length > 2 ? commaSeperator : spaceSeparator)
    + element.conjunction.toLowerCase() + " "
    + orderedValues[orderedValues.length - 1];
}

function getSingleSelectAnswerOutput(element: QuestionnaireElement, context: QuestionnaireRenderContext, preferOptionOutput: boolean, militaryTime: boolean) {
  const answerValues = context.getValuesByLinkId(element.linkId);

  const firstAnswer = answerValues[0];

  if (!firstAnswer) {
    return null;
  }

  if (answerValues.length > 0) {
    return getAnswerWithOutput(element, firstAnswer, preferOptionOutput, militaryTime);
  }

  return "";
}

function getAnswerWithOutput(element: QuestionnaireElement, answerValue: QuestionnaireAnswerValue, preferOptionOutput: boolean, militaryTime: boolean): string {
  const answerOption = element.answerOptions.find(a => isAnswerOptionValue(a, answerValue));
  if (answerOption) {
    // Note: Whitespace is allowed for the output when it is preferred
    if (preferOptionOutput && answerOption.output) {
      return answerOption.output;
    }

    // Otherwise, only accept output if it isn't empty/whitespace
    const output = answerOption.output?.trim() ? answerOption.output : undefined;
    return output ? output : getAnswerOptionDisplay(answerOption)?.toLocaleLowerCase() ?? "";
  }
  return getAnswerValueDisplay(answerValue, militaryTime) ?? "";
}

function headerGroupQuestionsHasAnswers(element: QuestionnaireElement, context: QuestionnaireRenderContext) {
  let index = context.getElementIndex(element.linkId);

  if (index === -1) {
    return false;
  }

  index++;
  let question = context.getElementAt(index);

  while (question) {
    if (question.type === QuestionFlavor.Header) {
      return false;
    }

    const answerValues = context.getValuesByLinkId(question.linkId);
    if (answerValues.some(answerValueHasValue)) {
      return true;
    }

    index++;
    question = context.getElementAt(index);
  }

  return false;
}
